import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { ProjectModal } from "../../Components/ProjectModal";
import { ProjectReleaseDateModal } from "../../Components/ProjectReleaseDateModal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../../Components/Alert";
import MaterialTable, { MTableToolbar } from "material-table";
import axios from "../../Engine/Infra/CustomAxios";
import { Button } from "../../Components/Button";
import { Loading } from "../../Components/Loading";
import * as tableProperties from "../../Shared/default_table_properties";
import { DateTime } from "luxon";
import confirmService from "../../Components/ConfirmDialog";
import Switch from "@material-ui/core/Switch";
import { searchIgnoreCaseAndAccents } from "../../Shared/functions";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SearchIcon from "@material-ui/icons/Search";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Tooltip from "@material-ui/core/Tooltip";
import {
  changeColumnFilters,
  changeSearchText,
  clearFilters,
} from "../../store/Project/Project.actions";

import {
  TableHeaderColumn,
  TableIcons,
  TableTextField,
} from "../../Components/MaterialTable/index";
import "./styles.css";

const compareDate = (data_current, data_next) => {
  const date_current = new Date(
    DateTime.fromFormat(data_current, "dd/MM/yyyy").toFormat("yyyy-MM-dd")
  );
  const date_next = new Date(
    DateTime.fromFormat(data_next, "dd/MM/yyyy").toFormat("yyyy-MM-dd")
  );
  return date_next < date_current ? -1 : date_next > date_current ? 1 : 0;
};

const getColumns = (handleProjectStatus) => [
  {
    title: <TableHeaderColumn title="Projeto" />,
    field: "name",
    render: (rowData) => (
      <Tooltip title={`Cód.: ${rowData.projectCode}`}>
        <span>{rowData.name}</span>
      </Tooltip>
    ),
    customFilterAndSearch: (term, rowData) =>
      searchIgnoreCaseAndAccents(term, rowData.name),
    filterComponent: (props) => (
      <TableTextField
        className="input-filter"
        id="field-filter-projeto"
        variant="outlined"
        size="small"
        value={props.columnDef.tableData.filterValue || ""}
        onChange={(event) => {
          props.onFilterChanged(
            props.columnDef.tableData.id,
            event.target.value
          );
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    ),
  },
  {
    title: <TableHeaderColumn title="Cliente" />,
    field: "client.name",
    customFilterAndSearch: (term, rowData) =>
      searchIgnoreCaseAndAccents(term, rowData.client.name),
    filterComponent: (props) => (
      <TableTextField
        className="input-filter"
        id="field-filter-cliente"
        variant="outlined"
        size="small"
        value={props.columnDef.tableData.filterValue || ""}
        onChange={(event) => {
          props.onFilterChanged(
            props.columnDef.tableData.id,
            event.target.value
          );
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    ),
  },
  {
    title: <TableHeaderColumn title="Unidade de Negócio" />,
    field: "businessUnit.name",
    customFilterAndSearch: (term, rowData) =>
      searchIgnoreCaseAndAccents(term, rowData.businessUnit?.name),
    filterComponent: (props) => (
      <TableTextField
        className="input-filter"
        id="field-filter-unidade"
        variant="outlined"
        size="small"
        value={props.columnDef.tableData.filterValue || ""}
        onChange={(event) => {
          props.onFilterChanged(
            props.columnDef.tableData.id,
            event.target.value
          );
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    ),
  },
  {
    title: <TableHeaderColumn title="Responsável" />,
    field: "responsible",
    render: (rowData) => rowData.responsible.map((_) => _.name).join(", "),
    filtering: true,
    customFilterAndSearch: (term, rowData) => {
      return (
        rowData.responsible.filter((resp) =>
          searchIgnoreCaseAndAccents(term, resp.name)
        ).length > 0
      );
    },
    filterComponent: (props) => (
      <TableTextField
        className="input-filter"
        id="field-filter-responsavel"
        variant="outlined"
        size="small"
        value={props.columnDef.tableData.filterValue || ""}
        onChange={(event) => {
          props.onFilterChanged(
            props.columnDef.tableData.id,
            event.target.value
          );
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    ),
  },
  {
    title: <TableHeaderColumn title="Data de Início" />,
    field: "beginningDate",
    customSort: (a, b) => compareDate(a.beginningDate, b.beginningDate),
    filterComponent: (props) => (
      <TableTextField
        className="input-filter"
        id="field-filter-data-inicio"
        variant="outlined"
        size="small"
        value={props.columnDef.tableData.filterValue || ""}
        onChange={(event) => {
          props.onFilterChanged(
            props.columnDef.tableData.id,
            event.target.value
          );
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeIcon />
            </InputAdornment>
          ),
        }}
      />
    ),
  },
  {
    title: <TableHeaderColumn title="Data de Liberação" />,
    field: "recordsReleaseDate",
    customSort: (a, b) =>
      compareDate(a.recordsReleaseDate, b.recordsReleaseDate),
    filterComponent: (props) => (
      <TableTextField
        className="input-filter"
        id="field-filter-data-liberacao"
        variant="outlined"
        size="small"
        value={props.columnDef.tableData.filterValue || ""}
        onChange={(event) => {
          props.onFilterChanged(
            props.columnDef.tableData.id,
            event.target.value
          );
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeIcon />
            </InputAdornment>
          ),
        }}
      />
    ),
  },
  {
    title: <TableHeaderColumn title="Situação" />,
    field: "status",
    disableClick: true,
    render: (rowData) => (
      <Switch
        checked={rowData.status === "ACTIVE"}
        onChange={(event) => handleProjectStatus(event, rowData)}
        color="primary"
        variant="outlined"
      />
    ),
    lookup: { ACTIVE: "Ativo", INACTIVE: "Inativo" },
  },
];

export const ProjectList = () => {
  const project_new_url = "/project/new";
  const project_view_url = "/project/view";

  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalReleaseDateOpen, setModalReleaseDateOpen] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [openAlert, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState();
  const [currentProject, setCurrentProject] = useState();
  const [[anchorEl, selectedRow], setAnchorEl] = React.useState([
    null,
    undefined,
  ]);
  const searchRef = new createRef();
  const tableRef = new createRef();
  const dispatch = useDispatch();
  const projectState = useSelector((state) => state.project);
  const [refreshStatusProject, setRefreshStatusProject] = useState(false);

  const handleCreateProject = () => {
    history.push(project_new_url);
  };
  const handleChangeReleaseDate = () => {
    setModalReleaseDateOpen(true);
  };
  const handleLoadingPage = (isLoading) => {
    setPageLoading(isLoading);
  };
  const handleCloseModal = () => {
    setCurrentProject("");
    setModalOpen(false);
  };
  const handleCloseModalReleaseDateOpen = () => {
    setModalReleaseDateOpen(false);
  };

  const handleAlert = ({ message, type }) => {
    setAlertMessage(message);
    setAlertType(type);
    handleOpenAlert();
  };

  useEffect(() => {
    const getProjects = async () => {
      const response = await axios.get("/projects");
      const projects = response.data.projects.map((project) => {
        project.beginningDate = DateTime.fromFormat(
          project.beginningDate,
          "yyyy-MM-dd"
        ).toFormat("dd/MM/yyyy");
        project.recordsReleaseDate = DateTime.fromFormat(
          project.recordsReleaseDate,
          "yyyy-MM-dd"
        ).toFormat("dd/MM/yyyy");
        
        return project
        // return {
        //   ...project,
        //   "businessUnit": project.businessUnit
        // }
    });
      
      setProjects(projects);
      setPageLoading(false);
    };

    getProjects();
  }, [refreshStatusProject]);

  const getProjectsRow = () => {
    return projects?.map((client) => {
      return {
        ...projects,
        "businessUnit": client.businessUnit
      }
    });
  }

  useEffect(() => {
    const applyFilters = async () => {
      const dataManager = tableRef.current.dataManager;
      dataManager.changeApplySearch(true);
      searchRef.current.onSearchChange(projectState.searchText);
      projectState.columnFilters.forEach((columnFilter) => {
        dataManager.changeFilterValue(
          columnFilter.columnId,
          columnFilter.value
        );
      });
      const tableState = dataManager.getRenderState();
      tableRef.current.setState(tableState);
    };
    applyFilters();
    return () => {
      const newPath = history.location.pathname;
      if (project_new_url !== newPath && project_view_url !== newPath) {
        dispatch(clearFilters());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenProject = (project) => {
    history.push({
      pathname: project_view_url,
      state: project,
    });
  };

  const handleTableFiltersChange = (filters) => {
    const columnFilters = filters.map((filter) => {
      const columnId = filter.column.tableData.id;
      const field = filter.column.field;
      const value = filter.value;
      return { columnId: columnId, field: field, value: value };
    });
    dispatch(changeColumnFilters(columnFilters));
  };

  const handleProjectStatus = async (_, project) => {
    const msg =
      project.status === "ACTIVE"
        ? "Após inativar o projeto, não será possível registrar ou editar horas neste projeto. Confirmar?"
        : "Deseja realmente ativar esse projeto?";
    const result = await confirmService.show({
      message: msg,
      title: "Desativar/Ativar Projeto",
    });
    if (!result) return;
    setPageLoading(true);
    const newStatus = project.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    try {
      const response = await axios.post("/projects/" + project.id + "/status", {
        status: newStatus,
      });
      if (response.data.success) {
        setRefreshStatusProject(!refreshStatusProject);
      } else {
        handleAlert({
          message: response.data.message || "Erro ao alterar status do projeto",
          type: "error",
        });
      }
    } catch (error) {
      handleAlert({
        message:
          error.response.data.message || "Erro ao alterar status do projeto",
        type: "error",
      });
    }
  };
  const handleTable = (action, data) => {
    switch (action) {
      case "CREATE":
        setProjects([...projects, data]);
        setColumns(getColumns(handleProjectStatus));
        break;
      case "UPDATE":
        const newProjects = projects.map((project) => {
          if (project.id === data.id) project = data;

          return project;
        });
        setProjects(newProjects);
        setColumns(getColumns(handleProjectStatus));
        break;
      case "DELETE":
        setProjects(
          projects.filter((projectList) => projectList.id !== data.id)
        );
        setColumns(getColumns(handleProjectStatus));
        handleAlert({
          message: "Cliente deletado com sucesso",
          type: "success",
        });
        break;
      case "CHANGE-RELEASE-DATE":
        data.projectIds.forEach((projectId) => {
          const project = projects.find((project) => project.id === projectId);
          project.recordsReleaseDate = DateTime.fromFormat(
            data.recordsReleaseDate,
            "yyyy-MM-dd"
          ).toFormat("dd/MM/yyyy");
        });

        setProjects([...projects]);
        setColumns(getColumns(handleProjectStatus));
        break;
      default:
        break;
    }
  };
  const handleCloseAlert = (_, __) => setOpen(false);
  const handleOpenAlert = () => setOpen(true);
  const [columns, setColumns] = useState(getColumns(handleProjectStatus));

  const options = ["Editar"];
  const handleClick = (event, rowData) => {
    setAnchorEl([event.currentTarget, rowData]);
  };

  const handleClose = () => {
    setAnchorEl([null, undefined]);
  };

  const handleEdit = () => {
    handleOpenProject(selectedRow);
    handleClose();
  };

  const actions = [
    (_) => ({
      icon: () => <MoreVertIcon />,
      tooltip: "Abrir Opções",
      onClick: handleClick,
    }),
  ];

  const handleOnchangeSearch = (value) => {
    dispatch(changeSearchText(value));
    searchRef.current.onSearchChange(value);
  };

  return (
    <Container className="table-projects">
      <Menu
        id="menu-item-row"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={handleEdit}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      <Snackbar
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleCloseAlert}
      >
        <Alert severity={alertType}>{alertMessage}</Alert>
      </Snackbar>
      <Loading isLoading={pageLoading} />
      {modalOpen ? (
        <ProjectModal
          handleAlert={handleAlert}
          currentProject={currentProject}
          open={modalOpen}
          onClose={handleCloseModal}
          handleLoadingPage={handleLoadingPage}
          handleTable={handleTable}
        />
      ) : null}
      {modalReleaseDateOpen ? (
        <ProjectReleaseDateModal
          projects={projects}
          open={modalReleaseDateOpen}
          onClose={handleCloseModalReleaseDateOpen}
          handleTable={handleTable}
        />
      ) : null}
      <div style={{ textAlign: "right" }}>
        <Button
          style={{ marginTop: "20px", marginRight: "10px" }}
          onClick={handleChangeReleaseDate}
        >
          Editar data de liberação
        </Button>
        <Button style={{ marginTop: "20px" }} onClick={handleCreateProject}>
          Novo Projeto
        </Button>
      </div>
      <div className="container-field-search-table">
        <TableTextField
          fullWidth
          placeholder="Pesquise aqui"
          variant="outlined"
          size="small"
          style={{}}
          value={projectState.searchText}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event) => handleOnchangeSearch(event.target.value)}
        />
      </div>
      <MaterialTable
        title=""
        onRowClick={(_, rowData) => handleOpenProject(rowData)}
        onFilterChange={handleTableFiltersChange}
        components={{
          Toolbar: (props) => (
            <div className="custom-toolbar">
              <MTableToolbar ref={searchRef} {...props} />
            </div>
          ),
        }}
        tableRef={tableRef}
        columns={columns}
        data={projects}
        icons={TableIcons}
        actions={actions}
        options={{
          ...tableProperties.options,
          headerStyle: {
            ...tableProperties.options.headerStyle,
            backgroundColor: "#414ABA",
            color: "#fff",
          },
        }}
        localization={tableProperties.localization}
      />
    </Container>
  );
};
