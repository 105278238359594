import { useSelector } from "react-redux";

export const useHasProfile = (profiles) => {
  const user = useSelector((state) => state.signedInUser);

  if (!user?.profile) return false;

  const rolesArray = Array.isArray(profiles) ? profiles : [profiles];

  return rolesArray.includes(user.profile.toLowerCase());
};
