import React from "react";
import "./styles.css";
import x from "../../Images/Icons/x.svg";
import { useHasProfile } from "../../hooks/useHasProfile";

const InnovationLawYear = ({ innovationLawYear, onAddDate, onRemoveDate }) => {
  const [selectedYear, setSelectedYear] = React.useState("");
  const currentYear = new Date().getFullYear();
  const startYear = 2000;
  const years = Array.from(
    { length: currentYear - startYear + 2 },
    (_, index) => startYear + index
  );
  const innovationYearsAsNumbers = innovationLawYear.map(Number);
  const hasAccess = useHasProfile("administrator");

  const handleAddDate = (event) => {
    const selectedYear = Number(event.target.value);
    if (selectedYear && !innovationYearsAsNumbers.includes(selectedYear)) {
      onAddDate(selectedYear);
      setSelectedYear("");
    }
  };

  const YearBadge = ({ year }) => (
    <div key={year} className="year-badge">
      <span>{year}</span>
      {hasAccess && (
        <button
          onClick={() => onRemoveDate(Number(year))}
          className="remove-button"
          aria-label={`Remover ano ${year}`}
        >
          <img src={x} alt="X icon" />
        </button>
      )}
    </div>
  );

  return (
    <div className="container-innovation-law">
      <label
        htmlFor="year-select"
        className="innovation-law-label-text"
        id="year-select-label"
      >
        Apropriado na Lei do Bem em:
      </label>
      <div className="years-container">
        <div className="badges-container" aria-live="polite" role="status">
          {innovationLawYear.length > 0 ? (
            innovationLawYear.map((year) => (
              <YearBadge
                key={year}
                year={year}
                aria-label={`Ano selecionado: ${year}`}
              />
            ))
          ) : (
            <div className="no-date">Nenhuma data adicionada.</div>
          )}
        </div>

        {hasAccess && (
          <select
            id="year-select"
            value={selectedYear}
            onChange={handleAddDate}
            className="select-year"
            aria-labelledby="year-select-label"
            aria-required="true"
            aria-invalid={selectedYear === "" ? "true" : "false"}
          >
            <option value="">Selecione o ano</option>
            {years
              .filter((year) => !innovationYearsAsNumbers.includes(year))
              .map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default InnovationLawYear;
